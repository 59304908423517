import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  status: "idle",
  error: "",
  remoteData: {
    aderess: "",
    password: "",
    username: "",
    path: "",
    port: "",
  },
  pathlocal: "",
  RemotesServers: [],
  DownloadToLocal: [],
  UploadToRemote: [],
};

export const RemoteToRemoteMigrationFiles = createAsyncThunk(
  "remote/RemoteToRemoteMigrationFiles",
  async (body) => {
    console.log(body);
    let data;
    try {
      const response = await axios.post(`/remoteServer/remote`, body);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const RemoteToLocalDownloadsFiles = createAsyncThunk(
  "remote/RemoteToLocalDownloadsFiles",
  async (body) => {
    console.log(body);
    let data;
    try {
      const response = await axios.post(
        `/remoteserver/downloadFileOrDirectory`,
        body
      );
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const LocalRemoteUploadsFiles = createAsyncThunk(
  "remote/LocalRemoteUploadsFiles",
  async (body) => {
    console.log(body);
    let data;
    try {
      const response = await axios.post(
        `/remoteserver/uploadFileOrDirectory`,
        body
      );
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "remote",
  initialState,
  reducers: {
    saveDataRemoteServer: (state, action) => {
      state.remoteData = action.payload;
    },
    savelocalpath: (state, action) => {
      state.pathlocal = action.payload;
    },
  },
  extraReducers: {
    [RemoteToRemoteMigrationFiles.pending]: (state) => {
      state.status = "loading";
    },
    [RemoteToRemoteMigrationFiles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.RemotesServers.push(action.payload);
    },
    [RemoteToRemoteMigrationFiles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [RemoteToLocalDownloadsFiles.pending]: (state) => {
      state.status = "loading";
    },
    [RemoteToLocalDownloadsFiles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.DownloadToLocal.push(action.payload);
    },
    [RemoteToLocalDownloadsFiles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [LocalRemoteUploadsFiles.pending]: (state) => {
      state.status = "loading";
    },
    [LocalRemoteUploadsFiles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.UploadToRemote.push(action.payload);
    },
    [LocalRemoteUploadsFiles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const { saveDataRemoteServer, savelocalpath } = slice.actions;
export const reducer = slice.reducer;

export default slice;
