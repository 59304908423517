import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
const initialState = {
  amazonData: [],
  status: "idle",
  error: null,
};

export const postfilesAmazonToDrive = createAsyncThunk(
  "amazon/postfilesAmazonToDrive",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };

        const response = await axios.post(
          `/amazon/uploadToDrive/${obj.params.name}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAmazonFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAmazonToDropBox = createAsyncThunk(
  "amazon/postfilesAmazonToDropBox",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        console.log(obj);
        const response = await axios.post(
          `/amazon/uploadToDropBox/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAmazonFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAmazonToAmazon = createAsyncThunk(
  "amazon/postfilesAmazonToAmazon",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(`/amazon/uploadToAmazon/`, objt);
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAmazonFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAmazonToAzure = createAsyncThunk(
  "amazon/postfilesAmazonToAzure",
  async (body, thunkAPI) => {
    let data;
    try {
      for (let el of body.el) {
        const obj = { ...body, el };
        const response = await axios.post(`/amazon/uploadToAzure/`, obj);
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAmazonFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "amazon",
  initialState,
  reducers: {
    addAmazonFileSuccess: (state, action) => {
      state.amazonData = [...state.amazonData, action.payload];
    },
  },
  extraReducers: {
    [postfilesAmazonToDrive.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAmazonToDrive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.amazonData.push(action.payload);
    },
    [postfilesAmazonToDrive.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [postfilesAmazonToDropBox.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAmazonToDropBox.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.amazonData.push(action.payload);
    },
    [postfilesAmazonToDropBox.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesAmazonToAmazon.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAmazonToAmazon.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.amazonData.push(action.payload);
    },
    [postfilesAmazonToAmazon.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesAmazonToAzure.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAmazonToAzure.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.amazonData.push(action.payload);
    },
    [postfilesAmazonToAzure.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { addAmazonFileSuccess } = slice.actions;
export const addAmazonFile = (data) => async (dispatch) => {
  try {
    dispatch(addAmazonFileSuccess(data));
  } catch (error) {
    console.log(error?.message);
  }
};

export default slice;
