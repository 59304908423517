import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
const initialState = {
  AzureFiles: [],
  azureData: {},
  CredentielAzure: {
    admin: "",
    connectionString: "",
  },
  status: "idle",
  error: null,
};

export const GetFilesFromAzure = createAsyncThunk(
  "azure/GetFilesFromAzure",
  async (body) => {
    let data;
    try {
      const response = await axios.post(`/azure/filesFromContainer`, body);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesAzureToDrive = createAsyncThunk(
  "azure/postfilesAzureToDrive",
  async (obj, thunkAPI) => {
    let data;
    try {
      console.log("objjjj", obj);
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/azure/azureToDrive/${obj.params.name}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAzureFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAzureToDropbox = createAsyncThunk(
  "azure/postfilesAzureToDropbox",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/azure/azureToDropBox/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAzureFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAzureToAmazon = createAsyncThunk(
  "azure/postfilesAzureToAmazon",
  async (body, thunkAPI) => {
    let data;

    try {
      for (let el of body.el) {
        const obj = { ...body, el };
        const response = await axios.post(`/azure/azureToAmazon`, obj);
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAzureFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesAzureToAzure = createAsyncThunk(
  "azure/postfilesAzureToAzure",
  async (body, thunkAPI) => {
    let data;
    try {
      for (let el of body.el) {
        const obj = { ...body, el };
        const response = await axios.post(`/azure/azureToAzure`, obj);
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addAzureFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteSourceFilesAzure = createAsyncThunk(
  "azure/deleteSourceFilesAzure",
  async (admin) => {
    let data;

    try {
      const response = await axios.delete(`/azure/deleteFiles/${admin}`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "azure",
  initialState,
  reducers: {
    saveCredentielAzure: (state, action) => {
      state.CredentielAzure = action.payload;
    },
    addAzureFileSuccess: (state, action) => {
      state.AzureFiles = [...state.AzureFiles, action.payload];
    },
  },
  extraReducers: {
    [GetFilesFromAzure.pending]: (state) => {
      state.status = "loading";
    },
    [GetFilesFromAzure.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.azureData = action.payload;
    },
    [GetFilesFromAzure.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesAzureToDrive.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAzureToDrive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.AzureFiles.push(action.payload);
    },
    [postfilesAzureToDrive.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesAzureToDropbox.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAzureToDropbox.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.AzureFiles.push(action.payload);
    },
    [postfilesAzureToDropbox.rejected]: (state) => {
      state.status = "failed";
    },
    // [postfilesAzureToAmazon.rejected]: (state, action) => {
    //   state.status = "failed";
    //   state.error = action.payload;
    // },

    // [postfilesAzureToAmazon.fulfilled]: (state, action) => {
    //   state.status = "succeeded";
    //   console.log("actionnnnnnnnn", action.payload);
    //   state.AzureFiles.push(action.payload);
    // },
    // [postfilesAzureToAmazon.rejected]: (state, action) => {
    //   state.status = "failed";
    //   state.error = action.payload;
    // },
    [postfilesAzureToAzure.pending]: (state) => {
      state.status = "loading";
    },
    [postfilesAzureToAzure.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action", action.payload);
      state.AzureFiles.push(action.payload);
    },
    [postfilesAzureToAzure.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deleteSourceFilesAzure.pending]: (state) => {
      state.status = "loading";
    },
    [deleteSourceFilesAzure.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [deleteSourceFilesAzure.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { saveCredentielAzure, addAzureFileSuccess } = slice.actions;

export const addAzureFile = (data) => async (dispatch) => {
  try {
    dispatch(addAzureFileSuccess(data));
  } catch (error) {
    console.log(error?.message);
  }
};

export default slice;
