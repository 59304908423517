import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "../slices/user";
import { reducer as formReducer } from "../slices/form";
import { reducer as backupReducer } from "../slices/backupService";
import { reducer as remoteReducer } from "../slices/remoteService";
import { reducer as amazonReducer } from "../slices/Amazon";
import { reducer as AzureReducer } from "../slices/azure";

const combinedReducer = combineReducers({
  user: userReducer,
  form: formReducer,
  services: backupReducer,
  remoteserver: remoteReducer,
  amazon: amazonReducer,
  azure: AzureReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
