import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  driveData: [],
  Data: [],
  dropboxData: [],
  filesDrives: {},
  filesAmazon: {},
  filesDropBox: {},
  status: "idle",
  driveStatus: "idle",
  error: null,
  methode: "",
  bucketName: "",
};

export const getfilesdrives = createAsyncThunk(
  "drive/getfilesdrives",
  async (obj) => {
    console.log(obj.body);
    let data;
    try {
      const response = await axios.post(
        `/drive/filesDrive/${obj.params.folderId}/${obj.params.name}`,
        obj.body
      );
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const migrateFilesFromDriveToAzure = createAsyncThunk(
  "drive/migrateFilesFromDriveToAzure",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/drive/driveToAzure/${obj.params.folderId}/${obj.params.service}/${obj.params.name}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDriveFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesdrivetodropbox = createAsyncThunk(
  "drive/postfilesdrivetodropbox",
  async (obj, thunkAPI) => {
    let data;

    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/drive/driveToDropbox/${obj.params.folderId}/${obj.params.service}/${obj.params.name}/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDriveFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchDriveFiles = createAsyncThunk(
  "drive/fetchDriveFiles",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/drive/searchFilesDrive/${obj.params.folderId}/${obj.params.service}/${obj.params.name}`,
          objt
        );

        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDriveFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesdrivetoamazon = createAsyncThunk(
  "drive/postfilesdrivetoamazon",
  async (obj, thunkAPI) => {
    let data;

    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/drive/driveToAmazon/${obj.params.folderId}/${obj.params.service}/${obj.params.name}`,
          objt
        );
        data = await response.data;
        console.log("dataaaa", data);
        if ((response.status = 200)) {
          thunkAPI.dispatch(addDriveFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const migrateFilesFromDropBoxToAzure = createAsyncThunk(
  "drive/migrateFilesFromDropBoxToAzure",
  async (obj, thunkAPI) => {
    console.log(obj.body);
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/dropbox/dropBoxToAzure/${obj.params.service}/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDropBoxFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesdropboxtodrive = createAsyncThunk(
  "dropbox/postfilesdropboxtodrive",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/dropbox/dropboxToDrive/${obj.params.service}/${obj.params.user}/${obj.params.name}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDropBoxFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesdropboxtoamazon = createAsyncThunk(
  "dropbox/postfilesdropboxtoamazon",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        console.log(obj);
        const response = await axios.post(
          `/dropbox/dropBoxToAmazon/${obj.params.service}/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDropBoxFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchDropBoxFiles = createAsyncThunk(
  "dropbox/fetchDropBoxFiles",
  async (obj, thunkAPI) => {
    let data;
    try {
      for (let el of obj.body.el) {
        const objt = { ...obj.body, el };
        const response = await axios.post(
          `/dropbox/searchFilesDropbox/${obj.params.service}/${obj.params.user}`,
          objt
        );
        data = await response.data;

        if ((response.status = 200)) {
          thunkAPI.dispatch(addDropBoxFile(data));
          continue;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getfilesdropbox = createAsyncThunk(
  "dropbox/getfilesdropbox",
  async (obj) => {
    console.log(obj.body);
    let data;
    try {
      const response = await axios.post(
        `/dropbox/allFiles/${obj.params.name}`,
        obj.body
      );
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getfilesamazon = createAsyncThunk(
  "amazon/getfilesamazon",
  async (obj) => {
    console.log(obj.body);
    let data;
    try {
      const response = await axios.post(`/amazon/downloadFromAmazon`, obj.body);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const postfilesDrive = createAsyncThunk(
  "drive/postfilesDrive",
  async (body) => {
    let data;
    try {
      const response = await axios.post(`/drive/uploadfiles`, body);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postfilesDropBox = createAsyncThunk(
  "dropbox/postfilesDropBox",
  async (body) => {
    let data;
    try {
      const response = await axios.post(`/dropbox/uplodesfilesdropBox`, body);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deletefilesdrive = createAsyncThunk(
  "drive/deletefilesdrive",
  async (name) => {
    let data;

    try {
      const response = await axios.delete(`/drive/deleteFiles/${name}`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deletefilesdropbox = createAsyncThunk(
  "dropbox/deletefilesdropbox",
  async (name) => {
    let data;

    try {
      const response = await axios.delete(`/dropbox/deleteFiles/${name}`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveBucketName: (state, action) => {
      state.bucketName = action.payload;
    },
    addDriveFileSuccess: (state, action) => {
      state.driveData = [...state.driveData, action.payload];
    },
    addDropBoxFileSuccess: (state, action) => {
      state.dropboxData = [...state.dropboxData, action.payload];
    },
  },
  extraReducers: {
    [fetchDriveFiles.pending]: (state) => {
      state.driveStatus = "loading";
    },
    [fetchDriveFiles.fulfilled]: (state, action) => {
      state.driveStatus = "succeeded";
      console.log(action.payload);
      state.driveData.push(action.payload);
    },
    [fetchDriveFiles.rejected]: (state, action) => {
      state.driveStatus = "failed";
      state.error = action.payload;
    },
    [getfilesdrives.pending]: (state) => {
      state.status = "loading";
    },
    [getfilesdrives.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.filesDrives = action.payload;
    },
    [getfilesdrives.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getfilesamazon.pending]: (state) => {
      state.status = "loading";
    },
    [getfilesamazon.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action.payload", action.payload);
      state.filesAmazon = action.payload;
    },
    [getfilesamazon.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesDrive.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesDrive.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      state.methode = action.payload;
    },
    [postfilesDrive.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [fetchDropBoxFiles.pending]: (state) => {
      state.status = "loading";
    },
    [fetchDropBoxFiles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.dropboxData.push(action.payload);
    },
    [fetchDropBoxFiles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getfilesdropbox.pending]: (state) => {
      state.status = "loading";
    },
    [getfilesdropbox.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.filesDropBox = action.payload;
    },
    [getfilesdropbox.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesDropBox.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesDropBox.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      state.methode = action.payload;
    },
    [postfilesDropBox.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [deletefilesdrive.pending]: (state) => {
      state.status = "loading";
    },
    [deletefilesdrive.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [deletefilesdrive.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deletefilesdropbox.pending]: (state) => {
      state.status = "loading";
    },
    [deletefilesdropbox.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [deletefilesdropbox.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [postfilesdrivetodropbox.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesdrivetodropbox.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.driveData.push(action.payload);
    },
    [postfilesdrivetodropbox.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [postfilesdropboxtodrive.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesdropboxtodrive.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.dropboxData.push(action.payload);
    },
    [postfilesdropboxtodrive.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [postfilesdrivetoamazon.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesdrivetoamazon.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.driveData.push(action.payload);
    },
    [postfilesdrivetoamazon.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [postfilesdropboxtoamazon.pending]: (state) => {
      state.poststatus = "loading";
    },
    [postfilesdropboxtoamazon.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.dropboxData.push(action.payload);
    },
    [postfilesdropboxtoamazon.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [migrateFilesFromDriveToAzure.pending]: (state) => {
      state.poststatus = "loading";
    },
    [migrateFilesFromDriveToAzure.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.driveData.push(action.payload);
    },
    [migrateFilesFromDriveToAzure.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
    [migrateFilesFromDropBoxToAzure.pending]: (state) => {
      state.poststatus = "loading";
    },
    [migrateFilesFromDropBoxToAzure.fulfilled]: (state, action) => {
      state.poststatus = "succeeded";
      console.log(action.payload);
      state.dropboxData.push(action.payload);
    },
    [migrateFilesFromDropBoxToAzure.rejected]: (state, action) => {
      state.poststatus = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { saveBucketName, addDriveFileSuccess, addDropBoxFileSuccess } =
  slice.actions;

export const addDriveFile = (data) => async (dispatch) => {
  try {
    dispatch(addDriveFileSuccess(data));
  } catch (error) {
    console.log(error?.message);
  }
};
export const addDropBoxFile = (data) => async (dispatch) => {
  try {
    dispatch(addDropBoxFileSuccess(data));
  } catch (error) {
    console.log(error?.message);
  }
};

export default slice;
